@font-face {
  font-family: "Lemonmilk Bold";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../../fonts/lemonmilk-bold.ttf");
}

@font-face {
  font-family: "Calibri";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../../fonts/Calibri Regular.ttf");
}

.field-title {
  text-align: left;
  font-family: "Lemonmilk Bold", serif;
  color: #454545;
  font-size: 18px;
  width: 70%;
}

.privacyPolicyLink {
  text-decoration: underline;
  color: blue !important;
}

.privacyPolicyLink span {
  text-decoration: underline;
  color: blue !important;
}

.privacyPolicy {
  font-family: "Calibri", Arial, sans-serif;
  opacity: 0.9;
  margin: 0;
  margin-left: 0 !important;
  display: flex;
}

.privacyPolicy span {
  opacity: 0.9 !important;
  margin: 0 !important;
  text-align: left !important;
  font-size: 12px !important;
  font-weight: 100 !important;
  color: #85827e;
}

.step-box {
  width: 28vw;
  background-color: #f5f5f2;
  padding: 40px;
  border-radius: 40px;
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
}

.customFormError {
  color: red !important;
  font-size: 11px !important;
  margin-top: 0 !important;
  text-align: center !important;
  padding-bottom: 0px !important;
}

.privacyPolicyInfo {
  font-size: 11px;
  opacity: 0.9 !important;
  color: #85827e;
  padding-left: 11px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.income-select {
  margin-top: 0px !important;
}

.product-select {
  margin-top: 15px !important;
}
.product-select p {
  margin-top: 0px;
}
.product-select label {
  margin-top: 5px;
}
.product-select label span {
  color: #70706f;
  font-size: 0.9rem;
  font-weight: bold;
}

.product-select label .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.privacyPolicyInfo p {
  margin-top: 18px;
  margin-bottom: 0px;
}

@media only screen and (max-width: 425px) {
  .income-formhelper {
    font-size: 9px !important;
  }

  .product-formhelper {
    font-size: 9px !important;
  }
  .product-step-fields {
    position: relative;
  }

  .name-step-fields {
    position: relative;
  }

  .privacyPolicyInfo {
    font-size: 8.5px;
    opacity: 0.9 !important;
    color: #85827e;
    padding-left: 11px;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .privacyPolicyInfo p {
    margin-top: 2px;
    margin-bottom: 0px;
  }

  .tax-id-step-fields {
    position: relative;
  }

  .contact-step-fields {
    position: relative;
    padding-top: 5%;
  }

  .birthdate-step-fields {
    position: relative;
  }

  .address-step-fields {
    position: relative;
  }

  .income-step-fields {
    position: relative;
  }

  .origin-step-fields {
    position: relative;
  }

  .result-step {
    position: relative;
    margin-top: 10% !important;
    text-align: center;
    height: 90%;
  }

  .result-step h1 {
    font-family: "Lemonmilk Bold", serif;
    font-size: 4vw !important;
  }

  .result-step p {
    font-size: 4vw !important;
    font-weight: 50;
  }

  .result-step img {
    width: 25%;
    height: 25%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .step-box {
    width: 70vw;
    background-color: #f5f5f2;
    padding: 5vw;
    border-radius: 5vw;
    position: relative;
    margin-left: 10vw;
    display: flex;
    flex-direction: column;
  }

  .return-btn {
    color: white !important;
    background-color: #dc43ae !important;
    border-radius: 0 !important;
    font-family: "Lemonmilk Bold", serif !important;
    top: 0;
  }
  .pagination-buttons {
    align-self: center;
    justify-self: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around !important;
    flex-direction: row !important;
  }
  .pagination-buttons Button {
    width: 40% !important;
    font-size: 3vw !important;
  }

  .customFormError {
    color: red !important;
    font-size: 9px !important;
    margin-top: 0 !important;
    text-align: center !important;
    padding-bottom: 0px !important;
  }

  .field-title {
    text-align: left;
    font-family: "Lemonmilk Bold", serif;
    color: #454545;
    font-size: 11px;
    width: 70%;
  }

  .step-fields input {
    font-size: 20px !important;
  }
  .step-fields .MuiSelect-selectMenu {
    font-size: 4vw !important;
  }
  .address-step-fields .field-title {
    font-size: 3vw !important;
  }
  .field-loading {
    display: block;
    position: absolute;
    top: -46vh;
    left: -20vw;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    background-size: 8vw;
    background-image: url("https://i.stack.imgur.com/MnyxU.gif");
    background-repeat: no-repeat;
    background-position: center;
  }
  .income-step-fields .MuiInput-formControl {
    width: 90%;
  }
  .address-step-fields {
    position: relative;
  }
}

@media only screen and (max-width: 300px) {
  .gerdauColabLabel span {
    text-align: left !important;
    font-family: "Lemonmilk Bold", serif !important;
    color: #454545 !important;
    font-size: 13px !important;
    width: 10% !important;
    height: 17px;
    padding-left: 14px;
  }

  .privacyPolicy span {
    opacity: 0.9 !important;
    margin: 0 !important;
    text-align: left !important;
    font-size: 10px !important;
    font-weight: 100 !important;
    color: #85827e !important;
  }

  .gerdauColabFormGroup .field-title {
    font-size: 3.2vmin !important;
  }

  .gerdauColabText {
    display: flex;
    font-size: 11px;
  }
  .gerdauColabButton {
    text-align: center;
    padding-top: 8px !important;
    position: relative;
  }
}

.gerdauColab {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 5px;
  position: relative;
}

.gerdauColabText {
  display: flex;
}

.gerdauColabText div {
  margin: 4px;
  font-size: 14px;
}

.gerdauColabText div p {
  font-family: "Calibri", Arial, sans-serif;
  opacity: 0.9;
  margin: 0;
  text-align: left;
  font-size: 2vmin;
  width: 90%;
  font-weight: 100;
  color: #85827e;
}

.gerdauColabText div p.title {
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1%;
  font-size: 2vmin;
  text-align: left;
  color: black;
}

.gerdauColabButton {
  text-align: center;
  padding-top: 15px;
  position: relative;
}

.gerdauColabFormGroup {
  position: relative;
  top: 0vh;
}

.gerdauColabFormGroup .field-title {
  font-size: 2.2vmin;
}

.gerdauColabLabel {
  text-align: left !important;
  font-family: "Lemonmilk Bold", serif !important;
  color: #454545 !important;
  font-size: 16px !important;
  width: 20% !important;
}

.gerdauColabLabel span {
  text-align: left !important;
  font-family: "Lemonmilk Bold", serif !important;
  color: #454545 !important;
  font-size: 16px !important;
  width: 20% !important;
}

.next-prev-btn {
  color: white !important;
  background-color: #00b6b2 !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
  margin-right: 5vw;
}

.next-prev-btn {
  color: white !important;
  background-color: #00b6b2 !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
  margin-right: 5vw;
}

.disabled-next-button {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
  margin-right: 5vw;
  box-shadow: none;
}

.next-prev-btn-gerdau {
  color: white !important;
  background-color: #0091cb !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
  margin-right: 5vw;
}

.submit-btn {
  color: white !important;
  background-color: #dc43ae !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
}

.submit-btn-gerdau {
  color: white !important;
  background-color: #ffd62d !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
}

.return-btn {
  color: white !important;
  background-color: #dc43ae !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
}

.return-btn-gerdau {
  color: white !important;
  background-color: #ffd62d !important;
  border-radius: 0 !important;
  font-family: "Lemonmilk Bold", serif !important;
}

.pagination-buttons {
  align-self: center;
  justify-self: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around !important;
  flex-direction: row !important;
}

.pagination-buttons Button {
  width: 40% !important;
  font-size: 1.2vmax;
}

.name-step-fields {
  position: relative;
}

.tax-id-step-fields {
  position: relative;
}

.contact-step-fields {
  position: relative;
  padding-top: 5%;
}

.birthdate-step-fields {
  position: relative;
}

.address-step-fields {
  position: relative;
}

.address-step-fields .field-title {
  font-size: 2.2vmin;
}

.income-step-fields {
  position: relative;
}

.origin-step-fields {
  position: relative;
}

.email-field {
  padding-top: 40px !important;
}

.result-step {
  position: relative;
  margin-top: 15% !important;
  text-align: center;
  height: 85%;
}

.step-fields {
  height: 100%;
  flex-grow: 1;
  display: flex;
}

.step-fields > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}

.step-fields input {
  font-size: 20px;
}

.step-fields .MuiSelect-selectMenu {
  font-size: 20px;
}

@media only screen and (max-width: 768px) {
  .income-step-fields .MuiInput-formControl {
    width: 80%;
  }
}

.result-step h1 {
  font-family: "Lemonmilk Bold", serif;
  font-size: 1.6vw;
}

.result-step p {
  font-size: 1.2vw;
  font-weight: 50;
}

.result-step img {
  width: 25%;
  height: 25%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.field-loading {
  display: block;
  position: absolute;
  top: -46vh;
  left: -38vw;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-size: 3vw;
  background-image: url("https://i.stack.imgur.com/MnyxU.gif");
  background-repeat: no-repeat;
  background-position: center;
}
