@font-face {
    font-family: "Lemonmilk Bold";
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url('../../fonts/lemonmilk-bold.ttf');
}

@media only screen and (max-width: 800px) {
    .form-message {
        font-family: "Lemonmilk Bold", serif;
        font-size: 3vmin;
        text-align: right;
        color: #3b3b3b;
        width: 30vw;
        margin-left: 8vw !important;
    }
}

.form-message {
    font-family: "Lemonmilk Bold", serif;
    font-size: 3vmin;
    text-align: right;
    color: #3b3b3b;
    width: 30vw;
    margin-left: 15vw;
}

.MuiInputLabel-formControl {
    font-size: 2.2vmin !important;
}

@media only screen and (max-width: 425px) {
    .form-message {
        font-family: "Lemonmilk Bold", serif;
        font-size: 3vmin;
        text-align: left;
        color: #3b3b3b;
        width: 70vw;
        margin-left: 15vw;
    }
    .MuiInputLabel-formControl {
        font-size: 4vw !important;
    }
}
