@font-face {
    font-family: "Calibri";
    font-style: normal;
    font-weight: normal;
    font-display: auto;
    src: url('../../../fonts/Calibri Regular.ttf');
}

.helperText {
    display: flex;
    margin-top: 10%;
}

.helperText div {
    margin: 2px;
}

.helperText div p {
    font-family: 'Calibri', Arial, sans-serif;
    opacity: .9;
    margin: 0;
    text-align: left;
    font-size: 12.5px;
    width: 90%;
    font-weight: 100;
    color: #85827e;
}

.helperText div p.title {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1%;
    font-size: 12px;
    text-align: left;
    color: black;
}


@media only screen and (max-width: 768px) {
    .helperText div p {
        font-family: 'Calibri', Arial, sans-serif;
        opacity: .9;
        margin: 0;
        text-align: left;
        font-size: 1.7vmin;
        width: 90%;
        font-weight: 100;
        color: #85827e;
    }

    .helperText div p.title {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1%;
        font-size: 1.7vmin;
        text-align: left;
        color: black;
    }

}

@media only screen and (max-width: 425px) {

    .helperText {
        display: flex;
        margin-top: 3%;
    }

    .helperText div p {
        font-family: 'Calibri', Arial, sans-serif;
        opacity: .9;
        margin: 0;
        text-align: left;
        font-size: 9px;
        width: 90%;
        font-weight: 100;
        color: #85827e;
    }

    .helperText div p.title {
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1%;
        font-size: 3.5vmin;
        text-align: left;
        color: black;
    }

}
