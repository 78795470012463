.loading {
  display: block;
  position: relative;
  z-index: 100;
  height: 100%;
  background-image: url("https://i.stack.imgur.com/MnyxU.gif");
  background-repeat: no-repeat;
  background-position: center;
  min-height: 300px;
}

@media only screen and (max-width: 425px) {
  .loading {
    display: block;
    position: relative;
    z-index: 100;
    height: 100%;
    background-image: url("https://i.stack.imgur.com/MnyxU.gif");
    background-repeat: no-repeat;
    background-position: center;
    min-height: 300px;
  }
}
